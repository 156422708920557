import * as React from "react"
import { Link } from "@StarberryUtils"
import Header from "@Components/Header/Header";
import Footer from "@Components/Footer/Footer";
import MarketPropertyComponents from "@Components/MarketProperty/MarketProperty"
// import  './SearchResult.scss';

const MarketProperty = ( props ) => {
  const [state,setState] = React.useState({
    showMenu:false
  })

  const handlerClick = () => {
    // console.log('okkk')
    setState({...state,showMenu:!state.showMenu})
  }
 
  
  return(
    <div className={state.showMenu ? "wrapper open-search-block" : "wrapper  wrapper-static"}>
    <Header showMenu={state.showMenu} handlerClick={handlerClick}/>
    <MarketPropertyComponents/> 
    <Footer/>
    </div>
         
  
)
  }



export default MarketProperty