import * as React from "react"
import PropertyBanner from '../MarketProperty/PropertyBanner/PropertyBanner'
import BreadcrumbsBottom from '../MarketProperty/BreadcrumbsBottom/BreadcrumbsHome'
const MarketProperty = ( props ) => {

  return(

      <div>
        <PropertyBanner/>
        <BreadcrumbsBottom/>
      </div>
         
  
)
  }



export default MarketProperty